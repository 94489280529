<div class="wrapper">
    <div class="arrow-steps clearfix">
        <div class="step hover" [routerLink]="'/fizetes'"> <span>Adatok</span> </div>
        <div class="step current"> <span>Összegzés</span> </div>
        <div class="step"> <span> Véglegesítés</span> </div>
    </div>
</div>
<div *ngFor="let profile of profileCustomer">
    <p class="cart-content">Kosár tartalma</p>
    <div class="mb-2" *ngFor="let product of toPayProduct">
        <span class="course-name">{{product.title}}</span>
        <span class="course-price">{{product.price}}</span>
    </div>
    <div *ngFor="let price of coupons" class="mb-2">
        <span class="course-name">{{price.name}}</span>
        <span class="course-price">{{price.price}}</span>
    </div>
    <div *ngFor="let price of cuoponPrice">
        <span class="course-name">Fizetendő</span>
        <span class="course-price">{{price.current}}</span>
    </div>
    <div class="line p-3"></div>
    <p class="cart-content">Számlázási adatok</p>
    <div style="padding-left:110px; width:900px; margin:35px auto">
    <div class="row mb-3">
      <div class="col-2">Név<sup>*</sup></div>
      <div class="col-5" style="padding-right:1rem  !important;"><input [formControl]="lastNameControl" type="text" style="width:100%;" [disabled]="true" /></div>
      <div class="col-5"><input [formControl]="firstNameControl" type="text" style="width: 100%;" [disabled]="true" /></div>
    </div>
    <div class="row mb-3">
      <div class="col-2">E-mail cím<sup>*</sup></div>
      <div class="col-10"><input [(ngModel)]="profile.email" style="width: 100%" type="text" disabled></div>
    </div>
    <div class="row mb-3">
      <div class="col-2">Cím<sup>*</sup></div>
      <div class="col-10" style="display:flex; flex-direction: column;">
        <select [(ngModel)]="profile.country" class="form-select form-control" style="width: 100%;" disabled>
          <option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</option>
        </select>
        <div *ngIf="!profile.country" class="error-message">
          Ország megadása kötelező!
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-2"></div>
      <div class="col-5" style="display:flex; flex-direction: column;padding-right:1rem  !important;">
        <input [formControl]="postalCodeControl" required placeholder="Irányítószám" style="width:100%" type="text"/>
        <div *ngIf="postalCodeControl.errors?.['required'] && (postalCodeControl.dirty || postalCodeControl.touched)" class="error-message">
          Irányítószám megadása kötelező!
        </div>
      </div>
      <div class="col-5" style="display:flex; flex-direction: column;">
        <input [formControl]="cityControl" required placeholder="Város" type="text" style="width:100%" />
        <div *ngIf="cityControl.errors?.['required'] && (cityControl.dirty || cityControl.touched)" class="error-message">
          Város megadása kötelező!
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-2"></div>
      <div class="col-5" style="display:flex; flex-direction: column;padding-right:1rem  !important;">
        <input [formControl]="addressControl" required placeholder="Utca" type="text" style="width: 100%" />
        <div *ngIf="addressControl.errors?.['required'] && (addressControl.dirty || addressControl.touched)" class="error-message">
          Utca megadása kötelező!
        </div>
      </div>
      <div class="col-5" style="display:flex; flex-direction: column;">
        <input [formControl]="houseNumberControl" required placeholder="Házszám" type="text" style="width: 100%;" />
        <div *ngIf="houseNumberControl.errors?.['required'] && (houseNumberControl.dirty || houseNumberControl.touched)" class="error-message">
          Házszám megadása kötelező!
        </div>
      </div>
    </div>
    </div>
    <div class="line p-3"></div>
    <div class="container">
        <p class="cart-content">Fizetési mód</p>
        <div class="flex-container m-2 justify-content-center">
            <div id="simplepay" class="pink-circle" [ngClass]="{ 'pink-fill': isPinkFill1 }" (click)="toggleColors(1)" (click)="onSimplePayClick()"></div>
            <img src="assets/sp.png" style="height:100px;">
        </div>
        <div class="flex-container m-2 justify-content-center">
            <div id="paypal" class="pink-circle" [ngClass]="{ 'pink-fill': isPinkFill2 }" (click)="toggleColors(2)" (click)="onPayPalClick()"></div>
            <img src="assets/pp.png" style="height:100px;">
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <button (click)="Pay()" class="btn btn-primary m-3">Fizetés</button>
    </div>
</div>
