import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FooldalService } from 'src/app/services/fooldal.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() menus: any;
  @Input() loggedUser: any;
  @Input() showImage?: boolean;
  @Input() isAdmin?: boolean;
  _displayName: string = '';
  enrolledCourse: any[] = [];
  subscriber: boolean = false;
  videostore: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private fooldalService: FooldalService
  ) { }
  isActiveMenu(menuPath: string) {
    return this.router.isActive(menuPath, true);
  }

  Logout() {
    const token = localStorage.getItem('login');
    if (token) {
      var login = JSON.parse(token);
      this.authService.fetchCsrfToken().subscribe((csrfToken: string) => {
        if (csrfToken) {
          this.authService.logout(login.logout_token, csrfToken).subscribe(logout => {
            window.location.reload();
          });
        }
      }, (error) => {

      });
    }
    this.router.navigateByUrl("/fooldal");
    localStorage.removeItem('product');
    localStorage.removeItem('display_name');
    localStorage.removeItem('user_id');
    localStorage.removeItem('api-key');
  }

  ngOnInit() {
    this.fooldalService.enrolledCourseLicens().subscribe((s:any) => {
      s.data.forEach((element:any) => {
        if (element.state === 'active') {
        const enrolledCourseDetails = {
          title: '',
          id: '',
          videostore: false
        }
        if (element.product_variation.product_id.type === 'product--videostore') {
          this.videostore = true;
        } else {
          enrolledCourseDetails.title = element.product_variation.product_id.field_course.title;
          enrolledCourseDetails.id = element.product_variation.product_id.field_course.id;

          if(!this.enrolledCourse.some(e => e.id === enrolledCourseDetails.id)) {
            this.enrolledCourse.push(enrolledCourseDetails);
          }
        }
      }
      });
    });
    /*
    this.fooldalService.enrolledUser().subscribe( enrolled => {
      for(const [key, value] of Object.entries(enrolled)){
        if(key === 'courses'){
          for(let i in value){
            const enrolledCourseDetails = {
              title: '',
              cid: ''
            }
            enrolledCourseDetails.title = value[i].title[0].value;
            enrolledCourseDetails.cid = value[i].cid[0].value;

            //this.enrolledCourse.push(enrolledCourseDetails);
          }
        }
      }
    })*/

    if (localStorage.getItem('login')) {
    this.authService.getCurrentUser().subscribe(s => {
      const value = s.data[0];
            const user = {
              name: '',
              roles: ''
            };
            user.name = value.attributes.name;
            for (let j in value.relationships) {
              for (let k in value.relationships.roles.data) {
                if (value.relationships.roles.data[k].meta.drupal_internal__target_id === 'videostore') {
                  this.videostore = true;
                }
              }
            }
            this._displayName = value.attributes.display_name;
            localStorage.setItem('display_name', value.attributes.display_name);
          });
        }
  }

  get displayName() {
    return this._displayName;
  }
}
