import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { tap } from 'rxjs';
import { FooldalService } from 'src/app/services/fooldal.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-osszegzes',
  templateUrl: './osszegzes.component.html',
  styleUrls: ['./osszegzes.component.css']
})
export class OsszegzesComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];
  isPinkFill1: boolean = false;
  isPinkFill2: boolean = false;
  orderId: string = '';
  cuoponPrice: any[] = [];
  coupons: any[] = [];
  paypal: boolean = false;
  firstNameControl: FormControl = new FormControl('', [Validators.required]);
  lastNameControl: FormControl = new FormControl('', [Validators.required]);
  postalCodeControl: FormControl = new FormControl('', [Validators.required]);
  cityControl: FormControl = new FormControl('', [Validators.required]);
  addressControl: FormControl = new FormControl('', [Validators.required]);
  houseNumberControl: FormControl = new FormControl('', [Validators.required]);
  countryList: {key: string, value: string}[] = [];

  constructor(private fooldalService: FooldalService, private authService: AuthService) { }

  toggleColors(circleNumber: number) {
    if (circleNumber === 1) {
      this.isPinkFill1 = true;
      this.isPinkFill2 = false;
    } else if (circleNumber === 2) {
      this.isPinkFill2 = true;
      this.isPinkFill1 = false;
    }
  }

  onSimplePayClick() {

  }

  onPayPalClick() {
    this.paypal = true;

  }

  Pay() {
    this.firstNameControl.markAsTouched();
    this.lastNameControl.markAsTouched();
    this.postalCodeControl.markAsTouched();
    this.cityControl.markAsTouched();
    this.addressControl.markAsTouched();
    this.houseNumberControl.markAsTouched();
    if (this.paypal) {
      const body = new HttpParams().set('grant_type', 'client_credentials');
      this.fooldalService.getPayPalAccesToken(body.toString()).subscribe(p => {
        for (const [key, value] of Object.entries(p)) {

          if (key === "access_token") {
            var accessToken = value;
            for (let i in this.toPayProduct) {
              var productTitle = this.toPayProduct[i].title;
            }
            for(let j in this.cuoponPrice){
              var productPrice = this.cuoponPrice[j].current;
              var withoutSpace  = productPrice.replace(/\s+/g, '').replace('Ft', '');
              var convertedPrice = withoutSpace.replace(',', '.');
            }
            const price = Math.trunc(this.cuoponPrice[0].current_number);

                      const createPayPal = {
                        "intent" : "CAPTURE",
                        "purchase_units" : [
                          {
                            "items" : [
                              {
                                "name" : this.toPayProduct[0].title,
                                "description" : this.toPayProduct[0].title,
                                "quantity" : "1",
                                "unit_amount" : {
                                  "currency_code" : "HUF",
                                  "value" : price
                                }
                              }]
                            ,
                            "amount" : {
                              "currency_code" : "HUF",
                              "value" : price,
                              "breakdown" : {
                                "item_total" : {
                                  "currency_code" : "HUF",
                                  "value" : price,
                                }
                              }
                            }
                          }
                        ],
                        "application_context" : {
                          "return_url" : environment.webBaseUrl + "/sikeres_fizetes",
                          "cancel_url" : environment.webBaseUrl + "/sikertelen_fizetes"
                        }
                      }
                      this.fooldalService.createPayPalOrder(createPayPal, accessToken).subscribe( p => {
                        for(const [kk, vv] of Object.entries(p)){
                          if(kk === 'id'){
                            localStorage.setItem('paypalApi', vv);
                          }
                          if(kk === 'links'){
                            for(let k in vv){
                              window.location.href = vv[1].href;

                            }
                          }
                        }
                      });
                    }
                  }
                });

            /*
            this.fooldalService.getHallSessionProduct().subscribe( s => {
              for(const [k,v] of Object.entries(s)){
                if(k === 'data'){

                  for(let j in v){

                    if(v[j].variations[0]) {


                    }
                    if(v[j].variations[0] && v[j].variations[0].title === productTitle){

                      const createPayPal = {
                        "intent" : "CAPTURE",
                        "purchase_units" : [
                          {
                            "items" : [
                              {
                                "name" : v[j].title,
                                "description" : v[j].title,
                                "quantity" : "1",
                                "unit_amount" : {
                                  "currency_code" : "HUF",
                                  "value" : convertedPrice
                                }
                              }
                            ],
                            "amount" : {
                              "currency_code" : v[j].variations[0].price.currency_code,
                              "value" : convertedPrice,
                              "breakdown" : {
                                "item_total" : {
                                  "currency_code" : v[j].variations[0].price.currency_code,
                                  "value" : convertedPrice
                                }
                              }
                            }
                          }
                        ],
                        "application_context" : {
                          "return_url" : "http://localhost:4200/sikeres_fizetes",
                          "cancel_url" : "http://localhost:4200/sikertelen_fizetes"
                        }
                      }


                      this.fooldalService.createPayPalOrder(createPayPal, accessToken).subscribe( p => {
                        for(const [kk, vv] of Object.entries(p)){
                          if(kk === 'id'){
                            localStorage.setItem('paypalApi', vv);
                          }
                          if(kk === 'links'){
                            for(let k in vv){
                              window.location.href = vv[1].href;

                            }
                          }
                        }
                      });
                    }
                  }
                }
              }
            });*/
  }
}

  ngOnInit() {

    this.fooldalService.getCountryCode().pipe(
      tap(c => {
        let countryArray = [];
        for (const [k, v] of Object.entries(c)) {
          if (k === 'available-countries') {
            for (const [key, value] of Object.entries(v)) {
              countryArray.push({key, value: value as string});
            }
          }
        }
        this.countryList = countryArray;
      })
    ).subscribe();

    this.fooldalService.getCurrentCart().subscribe((c: any) => {
      const latestCart = c.data[c.data.length - 1];
      latestCart.order_items.forEach((item: any) => {
        this.toPayProduct.push({
          title: item.purchased_entity.field_variation_name,
          price: item.purchased_entity.price.formatted,
          price_number: item.total_price.number
        });
      });
      latestCart.coupons.forEach((coupon: any) => {
        this.fooldalService.getPromotionById(coupon.promotion_id.id).subscribe((p: any) => {
          this.coupons.push({
            name: coupon.code + ' kupon',
            price: -1 * p.data.attributes.offer.target_plugin_configuration.amount.number + ' Ft'
          });
        });

      });
    });

    const objProductPrice = {
      cuopon_price: '',
      cuopon_name: '',
      current: '',
      current_number: ''
    }


    this.authService.getCurrentUser().subscribe(p => {
      const objProfile = {
        id: '',
        name: '',
        country: '',
        county: '',
        postal_code: '',
        city: '',
        address: '',
        email: ''
      };

      let objId = ''; // Új változó az obj.id helyett
      const valuee = p.data[0];
                objProfile.name = valuee.attributes.name;
                objProfile.email = valuee.attributes.mail;
                  var mail = objProfile.email;
                  objId = valuee.id;
                  this.fooldalService.getProfileCustomer().subscribe(p => {
                    for (const [key, value] of Object.entries(p)) {
                      if (key === 'data') {
                        for (let i in value) {
                          objProfile.id = value[i].relationships.uid.data.id;
                          if (objProfile.id === objId) { // Az új változót használjuk itt
                            objProfile.name = value[i].attributes.address.family_name + " " + value[i].attributes.address.given_name;
                            this.firstNameControl.setValue(value[i].attributes.address.given_name);
                            this.lastNameControl.setValue(value[i].attributes.address.family_name);
                            objProfile.country = value[i].attributes.address.country_code;
                            objProfile.postal_code = value[i].attributes.address.postal_code;
                            this.postalCodeControl.setValue(value[i].attributes.address.postal_code);
                            objProfile.city = value[i].attributes.address.locality;
                            this.cityControl.setValue(value[i].attributes.address.locality);
                            objProfile.address = value[i].attributes.address.address_line1 + " " + value[i].attributes.address.address_line2;
                            this.addressControl.setValue(value[i].attributes.address.address_line1);
                            this.houseNumberControl.setValue(value[i].attributes.address.address_line2);
                            this.postalCodeControl.disable();
                            this.houseNumberControl.disable();
                            this.cityControl.disable();
                            this.addressControl.disable();
                            this.firstNameControl.disable();
                            this.lastNameControl.disable();
                            objProfile.email = mail;
                          }
                        }
                      }
                    }

                    this.profileCustomer.push(objProfile);
                  });
    });
    const pIdLocalS = localStorage.getItem('productId');
    if (pIdLocalS !== null) {
      this.orderId = pIdLocalS;
    }
    this.fooldalService.getProductById(this.orderId).subscribe((order) => {
      for (const [key, value] of Object.entries(order)) {
        if (key === 'data') {
          objProductPrice.current = value.order_total.total.formatted;
          objProductPrice.current_number = value.order_total.total.number;
          if (value.order_total.adjustments.length > 0) {
            objProductPrice.cuopon_name = value.order_total.adjustments[0].label;
            objProductPrice.cuopon_price = value.order_total.adjustments[0].amount.formatted;
          }
          this.cuoponPrice.push(objProductPrice);
        }
      }
    });

  }

}
