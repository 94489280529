import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FooldalService } from 'src/app/services/fooldal.service';

@Component({
  selector: 'app-fizetes',
  templateUrl: './fizetes.component.html',
  styleUrls: ['./fizetes.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0 })))
    ])
  ]
})
export class FizetesComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];
  cuoponDatas: any = {};
  public orderId: string = "";
  inputText: string = '';
  succesfull: boolean | null = null;
  used: boolean | null = null;
  usedCuopons: any[] = [];
  countryList: {key: string, value: string}[] = [];
  firstNameControl: FormControl = new FormControl('', [Validators.required]);
  lastNameControl: FormControl = new FormControl('', [Validators.required]);
  postalCodeControl: FormControl = new FormControl('', [Validators.required]);
  cityControl: FormControl = new FormControl('', [Validators.required]);
  addressControl: FormControl = new FormControl('', [Validators.required]);
  houseNumberControl: FormControl = new FormControl('', [Validators.required]);
  cartId: any;
  coupons: any[] = [];
  cuoponPrice: any[] = [];
  loginError?: string;
  showLoginAlert: boolean = false;

  constructor(private fooldalService: FooldalService, private router: Router, private authService: AuthService) { }

  osszegzes() {
    this.firstNameControl.markAsTouched();
    this.lastNameControl.markAsTouched();
    this.postalCodeControl.markAsTouched();
    this.cityControl.markAsTouched();
    this.addressControl.markAsTouched();
    this.houseNumberControl.markAsTouched();
    if(!this.firstNameControl.invalid && !this.lastNameControl.invalid && this.houseNumberControl.valid && this.postalCodeControl.valid && this.cityControl.valid && this.addressControl.valid){
      let profile = this.profileCustomer[0];
      const address: any = {
        "given_name": this.firstNameControl.value,
        "family_name": this.lastNameControl.value,
        "country_code": profile.country,
      };
      address['locality'] = this.cityControl.value;
      address['postal_code'] = this.postalCodeControl.value;
      address['address_line1'] = this.addressControl.value;
      address['address_line2'] = this.houseNumberControl.value;
      const addressBody = {
        "data": {
          "type": "profile--customer",
          "id": profile.id,
          "attributes": {
            "address": address
          },
          "relationships": {
            "uid": {
              "data": {
                "type": "user--user",
                "id": localStorage.getItem('userUuid')?.replaceAll("\"", ""),
              }
            }
          }
        }
      }
      const updateOrderRequest = {
        "data": {
          "type": "order--default",
          "id": this.orderId,
          "attributes": {
            "billing_information": {
              "address": {
                "country_code": profile.country,
                "locality": this.cityControl.value,
                "postal_code": this.postalCodeControl.value,
                "address_line1": this.addressControl.value,
                "address_line2": this.houseNumberControl.value,
                "given_name": this.firstNameControl.value,
                "family_name": this.lastNameControl.value,
              },
              "tax_number": {
                "type": null,
                "value": null
              }
            }
          },
          "relationships": {
            "uid": {
              "data": {
                "type": "user--user",
                "id": localStorage.getItem('userUuid')?.replaceAll("\"", ""),
              }
            }
          }
        }
      };
      if(profile.id) {
        this.authService.updateAddress(addressBody).pipe(
          switchMap(_ => this.fooldalService.updateOrder(updateOrderRequest, this.orderId))
        )
        .subscribe(_ => this.router.navigate(['/osszegzes']), error => {
          this.showLoginAlert = true;
          this.loginError = error.error.errors[0].detail;
          console.log(error);
          if(error.error.errors.some((item:any) => item.source.pointer === '/data/attributes/address/postal_code')) {
            this.loginError = 'A megadott irányítószám formátuma, vagy a kiválasztott ország nem megfelelő!';
          }
        });
      } else {
        this.authService.registerAddress(addressBody).pipe(
          switchMap(_ => this.fooldalService.updateOrder(updateOrderRequest, this.orderId))
        )
        .subscribe(_ => this.router.navigate(['/osszegzes']), error => {
          this.showLoginAlert = true;
          this.loginError = error.error.errors[0].detail;
          console.log(error);
          if(error.error.errors.some((item:any) => item.source.pointer === '/data/attributes/address/postal_code')) {
            this.loginError = 'A megadott irányítószám formátuma, vagy a kiválasztott ország nem megfelelő!';
          }
        });
      }
    }
  }

  ngOnInit() {
    this.refreshCart();

    this.fooldalService.getCountryCode().pipe(
      tap(c => {
        let countryArray = [];
        for (const [k, v] of Object.entries(c)) {
          if (k === 'available-countries') {
            for (const [key, value] of Object.entries(v)) {
              countryArray.push({key, value: value as string});
            }
          }
        }
        this.countryList = countryArray;
      })
    ).subscribe();

    this.authService.getCurrentUser().subscribe(p => {
      const objProfile = {
        id: '',
        name: '',
        country: '',
        county: '',
        postal_code: '',
        city: '',
        address: '',
        email: ''
      };

      let objId = ''; // Új változó az obj.id helyett
      const valuee = p.data[0];
                  objProfile.name = valuee.attributes.name;
                  objProfile.email = valuee.attributes.mail;
                  var mail = objProfile.email;
                  objId = valuee.id;
                  this.fooldalService.getCurrentCustomerProfile().subscribe((p:any) => {
                    const value = p.data;
                    objProfile.name = value.attributes.address.family_name + " " + value.attributes.address.given_name;
                    this.firstNameControl.setValue(value.attributes.address.given_name);
                    this.lastNameControl.setValue(value.attributes.address.family_name);
                    objProfile.country = value.attributes.address.country_code;
                    objProfile.postal_code = value.attributes.address.postal_code;
                    this.postalCodeControl.setValue(value.attributes.address.postal_code);
                    objProfile.city = value.attributes.address.locality;
                    this.cityControl.setValue( value.attributes.address.locality);
                    objProfile.address = value.attributes.address.address_line1 + " " + value.attributes.address.address_line2;
                    this.addressControl.setValue(value.attributes.address.address_line1);
                    this.houseNumberControl.setValue(value.attributes.address.address_line2);
                    objProfile.id = value.id;
                    objProfile.email = mail;
                    this.firstNameControl.disable();
                    this.lastNameControl.disable();
                    this.profileCustomer.push(objProfile);
                  });
    });
  }

  private refreshCart(): void {
    this.toPayProduct = [];
    this.usedCuopons = [];
    this.coupons = [];
    this.cuoponPrice = [];
    this.fooldalService.getCurrentCart().subscribe((c: any) => {
      const latestCart = c.data[c.data.length - 1];
      this.cartId = latestCart.id;

      latestCart.order_items.forEach((item: any) => {
        this.toPayProduct.push({
          title: item.purchased_entity.field_variation_name,
          price: item.purchased_entity.price.formatted,
          price_number: item.total_price.number
        });
      });

      if(Array.isArray(latestCart.coupons) && latestCart.coupons.length > 0) {
      latestCart.coupons.forEach((coupon: any) => {
        this.fooldalService.getPromotionById(coupon.promotion_id.id).subscribe((p: any) => {
          this.coupons.push({
            name: coupon.code + ' kupon',
            price: -1 * p.data.attributes.offer.target_plugin_configuration.amount.number + ' Ft'
          });
          this.usedCuopons.push(coupon.code);
        });

      });
      }
    });
    const pIdLocalS = localStorage.getItem('productId');
    if (pIdLocalS !== null) {
      this.orderId = pIdLocalS;
    }
    const objProductPrice = {
      cuopon_price: '',
      cuopon_name: '',
      current: '',
      current_number: ''
    }
    this.fooldalService.getProductById(this.orderId).subscribe((order) => {
      for (const [key, value] of Object.entries(order)) {
        if (key === 'data') {
          objProductPrice.current = value.order_total.total.formatted;
          objProductPrice.current_number = value.order_total.total.number;
          if (value.order_total.adjustments.length > 0) {
            objProductPrice.cuopon_name = value.order_total.adjustments[0].label;
            objProductPrice.cuopon_price = value.order_total.adjustments[0].amount.formatted;
          }
          this.cuoponPrice.push(objProductPrice);
        }
      }
    });
  }

  addCuopon() {

    this.succesfull = null;
    this.used = null;
    if (this.usedCuopons.includes(this.inputText)) {
      this.used = true;
      return;
    }
    this.fooldalService.getCouponByCode(this.inputText).subscribe((c: any) => {
      if(c.data.length === 0) {
        this.succesfull = false;
        return;
      }
      const coupon = c.data[0];
      let id = coupon.id;
      let type = coupon.type;
      this.fooldalService.addCuopon({
        "data": [
          {
            id,
            type
          }
        ]
      }, this.cartId).subscribe({
        next: (success) => {
        this.usedCuopons.push(this.inputText);
        this.refreshCart();
        this.succesfull = true;
        },
        error: (error) => {
          this.succesfull = false;
        }
    });
    });
  }

}
