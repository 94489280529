<div class="container">
    <div class="wrapper">
        <div class="arrow-steps clearfix">
            <div class="step current"  [routerLink]="'/fizetes'"> <span>Adatok</span> </div>
            <div class="step"> <span>Összegzés</span> </div>
            <div class="step"> <span> Véglegesítés</span> </div>
        </div>
    </div>
    <div *ngFor="let profile of profileCustomer">
        <p class="cart-content">Kosár tartalma</p>
        <div class="mb-2" *ngFor="let product of toPayProduct">
            <span class="course-name">{{product.title}}</span>
            <span class="course-price">{{product.price}}</span>
        </div>
        <div *ngFor="let price of coupons" class="mb-2">
          <span class="course-name">{{price.name}}</span>
          <span class="course-price">{{price.price}}</span>
        </div>
        <div *ngFor="let price of cuoponPrice">
            <span class="course-name">Fizetendő</span>
            <span class="course-price">{{price.current}}</span>
        </div>
        <div class="line p-3"></div>
        <p class="cart-content">Számlázási adatok</p>
        <div style="padding-left:110px; width:900px; margin:35px auto">
        <div class="row mb-3">
          <div class="col-2">Név<sup>*</sup></div>
          <div class="col-5"  style="padding-right:1rem  !important;"><input [formControl]="lastNameControl" type="text" style="width:100%;" [disabled]="true" /></div>
          <div class="col-5"><input [formControl]="firstNameControl" type="text" style="width: 100%;" [disabled]="true" /></div>
        </div>
        <div class="row mb-3">
          <div class="col-2">E-mail cím<sup>*</sup></div>
          <div class="col-10"><input [(ngModel)]="profile.email" style="width: 100%" type="text" disabled></div>
        </div>
        <div class="row mb-3">
          <div class="col-2">Cím<sup>*</sup></div>
          <div class="col-10" style="display:flex; flex-direction: column;">
            <select [(ngModel)]="profile.country" class="form-select form-control" style="width: 100%;">
              <option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</option>
            </select>
            <div *ngIf="!profile.country" class="error-message">
              Ország megadása kötelező!
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-2"></div>
          <div class="col-5" style="display:flex; flex-direction: column;padding-right:1rem  !important;">
            <input [formControl]="postalCodeControl" required placeholder="Irányítószám" style="width:100%" type="text"/>
            <div *ngIf="postalCodeControl.errors?.['required'] && (postalCodeControl.dirty || postalCodeControl.touched)" class="error-message">
              Irányítószám megadása kötelező!
            </div>
          </div>
          <div class="col-5" style="display:flex; flex-direction: column;">
            <input [formControl]="cityControl" required placeholder="Város" type="text" style="width:100%" />
            <div *ngIf="cityControl.errors?.['required'] && (cityControl.dirty || cityControl.touched)" class="error-message">
              Város megadása kötelező!
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-2"></div>
          <div class="col-5" style="display:flex; flex-direction: column;padding-right:1rem !important;">
            <input [formControl]="addressControl" required placeholder="Utca" type="text" style="width: 100%" />
            <div *ngIf="addressControl.errors?.['required'] && (addressControl.dirty || addressControl.touched)" class="error-message">
              Utca megadása kötelező!
            </div>
          </div>
          <div class="col-5" style="display:flex; flex-direction: column;">
            <input [formControl]="houseNumberControl" required placeholder="Házszám" type="text" style="width: 100%;" />
            <div *ngIf="houseNumberControl.errors?.['required'] && (houseNumberControl.dirty || houseNumberControl.touched)" class="error-message">
              Házszám megadása kötelező!
            </div>
          </div>
          <div class="my-3" *ngIf="loginError && showLoginAlert">
            <p class="alert alert-danger">{{loginError}}</p>
          </div>
        </div>
        </div>
        <div class="line p-3"></div>
        <div class="flex-container mb-2">
          <span>Kedvezményes kupon</span>
          <input [(ngModel)]="inputText" class="course" type="text">
          <button class="btn btn-primary" (click)="addCuopon()">Kupon érvényesítés</button>
        </div>
        <div class="text-center cuopon-text" *ngIf="succesfull" [@fadeInOut]>
          Sikeresen érvényesítetted a kuponod!
      </div>
      <div class="text-center cuopon-text-error" *ngIf="succesfull === false" [@fadeInOut]>
          Nem sikerült érvényesíteni a kuponod!
      </div>
      <div class="text-center cuopon-text-error" *ngIf="used" [@fadeInOut]>
          A kupont már érvényesítetted!
    </div>
        <div class="flex-container cuopon-text" *ngIf="usedCuopons.length">
          <span>Felhasznált kuponok:</span>
        </div>
        <div class="flex-container cuopon-text" *ngFor="let coupon of usedCuopons">
          <span>{{coupon}}</span>
        </div>

        <div class="d-flex justify-content-center">
            <button (click)="osszegzes()" class="btn btn-primary m-3 p-2">Tovább</button>
        </div>
    </div>
</div>

<!--
    reszponzivhoz
            <div class="arrow-steps clearfix">
            <div class="row">
                <div class="col-sm-4">
                    <div class="step current"> <span>Adatok</span> </div>
                </div>
                <div class="col-sm-4">
                    <div class="step"> <span>Összegzés</span> </div>
                </div>
                <div class="col-sm-4">
                    <div class="step"> <span> Végelegesítés</span> </div>
                </div>
            </div>
        </div>
-->
