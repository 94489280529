import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FooldalService } from 'src/app/services/fooldal.service';

@Component({
  selector: 'app-sikeres-kurzus',
  templateUrl: './sikeres-kurzus.component.html',
  styleUrls: ['./sikeres-kurzus.component.css']
})
export class SikeresKurzusComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];

  constructor(private fooldalService: FooldalService, private router: Router) { }

  ngOnInit() {
    const obj = {
      title: '',
      price: ''
    }
    if (localStorage.getItem('product')) {
      var current = JSON.parse(localStorage.getItem('product')!)
    }
    obj.title = current.title;
    obj.price = current.price;
    this.toPayProduct.push(obj)

    var localPId = localStorage.getItem('productId');
    var paypalId = localStorage.getItem('paypalApi');

    this.fooldalService.getPaypalGatewayData().subscribe(gw => {
      const data = gw.data[0];

    const paymentBody = {
      "data":
        {
          "type": "order--default",
          "id": localPId,
          "attributes": {
            "payment_instrument": {
              "payment_gateway_id": data.attributes.label,
              "payment_method_type": "paypal_checkout",
              "payment_gateway_mode": data.attributes.configuration.mode,
              "payment_details": {
                "paypal_remote_id": paypalId
              }
            }
          }
        }
    }

    const startBody = {
      "data": {
        "type": "payment--paypal-checkout",
        "attributes": {
          "capture": true
        }
      }
    }


    this.fooldalService.addPaymentMethod(paymentBody, localPId).subscribe( p => {
      this.fooldalService.startPayment(startBody, localPId).subscribe( f => {
        setTimeout(() => {
          this.router.navigateByUrl('/elofizetes');
        }, 5000);
      })
    });
  });

  }

}
