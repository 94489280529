import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { faPauseCircle, faPlayCircle, faWindowMaximize, faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { Observable, map, tap } from 'rxjs';
import { VideoStatusService } from 'src/app/services/video-status.service';
import videojs from 'video.js';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css'],
})
export class VideoPlayerComponent {
  faPlay = faPlayCircle;
  faPause = faPauseCircle;
  faWindow = faWindowMaximize;
  faWindowRestore = faWindowRestore;

  constructor(private videoStatusService: VideoStatusService, private breakpointObserver: BreakpointObserver) {}

  @ViewChild('target', { static: true }) target!: ElementRef;

  @Input() options?: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    poster: string;
    controlBar?: videojs.ControlBarOptions;
    sources: {
      src: string;
      type: string;
      label: string;
    }[];
  };
  @Input() paddingTop: string = '0px';
  player!: videojs.Player;
  isTablet$!: Observable<boolean>;

  ngOnInit() {
    const self = this;
    this.isTablet$ = this.breakpointObserver.observe(Breakpoints.TabletPortrait)
    .pipe(
      map(result => result.matches),
      map(_ => false),
      tap(isTablet => {
        if(this.options) {
          this.options.controlBar = {
            playToggle: !isTablet,
            fullscreenToggle: !isTablet,
            pictureInPictureToggle: !isTablet,
            progressControl: {
              seekBar: true
            },
            volumePanel: !isTablet
          }
        }
        if(this.player) {
          this.player.dispose();
        }
        const o = this.options;
        this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
          // Ellenőrizd, hogy van-e már minőségváltó a control bar-ban
          let qualitySelector = this.controlBar.el().querySelector('select');

          if (!qualitySelector) {
            // Ha nincs, akkor hozz létre egy select elemet a minőségváltáshoz
            qualitySelector = document.createElement('select');
            qualitySelector.style.background = 'transparent';
            qualitySelector.style.border = 'none';
            qualitySelector.style.color = 'white';

            // Adj hozzá a control bar-hoz
            this.controlBar.el().appendChild(qualitySelector);

            // Eseménykezelő a minőségváltásra
            o!.sources?.forEach((source: any) => {
              const option = document.createElement('option');
              option.value = source.src;
              option.text = source.label;
              option.style.background = '#254350';
              if(qualitySelector){
                qualitySelector.appendChild(option);
              }
            });

            qualitySelector.addEventListener('change', (event: any) => {
              const selectedSrc = event.target.value;
              this.src(selectedSrc);
            });
          }

          this.on('loadedmetadata', () => {
            // Eseménykezelő a minőségváltásra (opcionális, ha külön minőségváltó eseménykezelőre van szükség)
          });

          this.on('timeupdate', () => {
            var duration = this.duration();
            var currentTime = this.currentTime();
            var percentageWatched = (currentTime / duration) * 100;

            if (percentageWatched >= 90) {
              this.off('timeupdate');
            }
          });

          this.on('ended', () => {
            self.videoStatusService.setVideoEnded(true); // Beállítja a videóállapotot

          });
        });
      }));
  }

  playPause(): void {
    this.player.paused() ? this.player.play() : this.player.pause();
  }

  fullScreen(): void {
    this.player.requestFullscreen();
  }

  pip(): void {
    this.player.requestPictureInPicture();
  }

  get playing(): boolean {
    return !this.player.paused();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
